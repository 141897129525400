<template>
  <span class="group flex flex-row items-center py-4 lg:py-2 lg:pr-6 text-black text-xl lg:text-2xl font-semibold leading-normal"
    :class="{
      'active': active,
      'hover:text-black cursor-pointer': !noSelect,
      'justify-center': center
    }"
    @click="$emit('clicked')"
  >
    <slot>
    </slot>
  </span>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
export default {
  name: "HomepageSelector",
  components: {BaseIcon},
  props: {
    active: Boolean,
    center: Boolean,
    icon: {
      default: 'chevronRight',
      type: String,
    },
    noSelect: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selected: null,
    }
  }
}
</script>

<style scoped>

.active {
  color: theme('colors.black');
}

</style>
