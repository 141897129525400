<template>
  <t-card variant="homepage">
    <div class="flex lg:flex-row flex-col">
      <slot name="leftSide">
        <div class="lg:w-1/2 lg:px-24" :class="{'order-2': reverse}">
          <div class="font-helvNeu text-3xl lg:text-5xl leading-normal text-redAccentLight text-center my-8 lg:mt-0 lg:mb-16">
            <slot name="leftTitle">
            </slot>
          </div>
          <div class="text-lg font-normal leading-normal mb-8">
            <slot name="leftText">
            </slot>
          </div>
        </div>
        <div class="w-full lg:w-540 lg:block mt-0" :class="{'order-1': reverse}">
          <slot name="rightContent">
          </slot>
        </div>
      </slot>
    </div>
  </t-card>
</template>

<script>
export default {
  name: "HomepageSection",
  props: {
    reverse: Boolean,
  }
}
</script>
