<template>
  <div
    class="w-full lg:my-30 prlx-img lg:h-800"
    data-z-index="1"
  ></div>
</template>
<script>
export default {
  name: "HomepageParallax",
}
</script>

